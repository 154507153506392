import { Navigation, Swiper } from 'swiper';

class Article10 {
  /**
   * @type {HTMLIFrameElement}
   */
  currentVideo;
  /**
   * @type {HTMLElement}
   */
  currentCover;
  /**
   * @type {HTMLElement}
   */
  currentPlay;
  /**
   * @type {HTMLElement}
   */
  activeTabNav;
  /**
   * @type {HTMLElement}
   */
  activeTab;
  init() {
    this.animationInit();
    this.sliderInit();
    this.videoInit();
    this.tabsInit();
  }

  animationInit() {
    document.querySelectorAll('.article-10-info').forEach((el) => {
      el.classList.add('animate', 'animate__fadeInUp');
    });

    document
      .querySelectorAll('.article-10-tabs-navigation-item')
      .forEach((el, i) => {
        el.classList.add('animate', 'animate__fadeInUp');
        el.dataset.wowDelay = '0.' + i + 's';
      });
  }

  sliderInit() {
    Swiper.use([Navigation]);
    document.querySelectorAll('.article-10-slider').forEach((el) => {
      const next = el.parentElement.querySelector('.article-10-slider-next');
      const prev = el.parentElement.querySelector('.article-10-slider-prev');
      const slider = new Swiper(el, {
        navigation: {
          nextEl: next,
          prevEl: prev,
        },
        spaceBetween: 120,
      });

      slider.on('slideChange', (i) => {
        this.stopVideo();
      });
    });
  }

  videoInit() {
    document.querySelectorAll('.article-10-slider-video').forEach((el) => {
      const video = el.querySelector('.article-10-slider-video-iframe');
      const cover = el.querySelector('.article-10-slider-video-cover');
      const play = el.querySelector('.article-10-slider-video-play');

      if (play) {
        play.onclick = () => {
          this.playVideo(video, cover, play);
        };
      }

      if (cover) {
        cover.onclick = () => {
          this.playVideo(video, cover, play);
        };
      }
    });
  }

  /**
   *
   * @param video {HTMLIFrameElement}
   * @param cover {HTMLElement}
   * @param play {HTMLElement}
   */
  playVideo(video, cover, play) {
    this.currentVideo = video;
    this.currentCover = cover;
    this.currentPlay = play;
    cover.style.opacity = '0';
    play.style.opacity = '0';
    setTimeout(() => {
      cover.style.display = 'none';
      play.style.display = 'none';
      video.contentWindow.postMessage(
        '{"event":"command","func":"' + 'playVideo' + '","args":""}',
        '*'
      );
    }, 300);
  }

  stopVideo() {
    if (this.currentVideo) {
      this.currentCover.style.display = 'block';
      this.currentPlay.style.display = 'block';
      this.currentCover.style.opacity = '1';
      this.currentPlay.style.opacity = '1';
      this.currentVideo.contentWindow.postMessage(
        '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
        '*'
      );

      this.currentCover = undefined;
      this.currentPlay = undefined;
      this.currentVideo = undefined;
    }
    const noYoutube = this.activeTab
      .querySelector('.swiper-slide-active')
      ?.querySelector('.no-youtube');
    if (noYoutube) {
      const src = noYoutube.src;
      noYoutube.src = src;
    }
  }

  tabsInit() {
    document
      .querySelectorAll('.article-10-tabs-navigation-item')
      .forEach((el, i) => {
        if (i === 0) {
          this.activeTabNav = el;
          this.activeTab = document.querySelector('#' + el.dataset.tab);
          this.tabShow();
        }
        el.onclick = () => {
          if (el.classList.contains('active')) return;
          this.tabHide();
          this.activeTab = document.querySelector('#' + el.dataset.tab);
          this.activeTabNav = el;
          this.tabShow();
        };
      });
  }

  tabHide() {
    this.activeTab.classList.remove('active');
    this.activeTabNav.classList.remove('active');
    this.stopVideo();
  }

  tabShow() {
    this.activeTabNav.classList.add('active');
    this.activeTab.classList.add('active');
  }
}

export const article10 = new Article10();
