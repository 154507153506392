class Article01Arrow {
  /**
   * @type {HTMLElement}
   */
  arrow;
  init() {
    this.arrow = document.querySelector('.article-01-arrow');
  }

  animate() {
    this.arrow.querySelectorAll('.article-01-arrow-item').forEach((el, i) => {
      setTimeout(() => {
        el.style.opacity = 1;
      }, (i + 1) * 200);
    });
  }
}

export const article01Arrow = new Article01Arrow();
