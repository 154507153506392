import WOW from 'wow.js/dist/wow';
import { article01Arrow } from '@/components/articles/article-01/article-01-arrow';

class Animate {
  init() {
    const animate = new WOW({
      boxClass: 'animate',
      animateClass: 'animate__animated',
      offset: 0,
      mobile: true,
      live: true,
      callback: function (box) {
        if (box.classList.contains('article-01-arrow')) {
          article01Arrow.animate();
        }
      },
      scrollContainer: null,
      resetAnimation: true,
    });
    animate.init();
  }
}

export const animate = new Animate();
