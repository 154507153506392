import { Navigation, Swiper } from 'swiper';
import { YoutubeVideo } from '@/components/youtube-video';

class News extends YoutubeVideo {
  constructor() {
    super();
  }
  init() {
    this.initSlider();
    this.initVideo();
  }

  initSlider() {
    const slider = document.querySelector('.news-item-slider .swiper');
    if (!slider) {
      return;
    }

    Swiper.use([Navigation]);
    const swiper = new Swiper(slider, {
      slidesPerView: true,
      navigation: {
        prevEl: '.news-item-slider-navigation-prev',
        nextEl: '.news-item-slider-navigation-next',
      },
    });
  }

  initVideo() {
    document.querySelectorAll('.news-item-video').forEach((el) => {
      const cover = el.querySelector('.news-item-video-cover');
      const iframe = el.querySelector('.news-item-video-iframe');
      cover.onclick = () => {
        this.playVideo(iframe, cover);
      };
    });
  }
}

export const news = new News();
