import { MainSlider } from "@/components/home/main-slider";
import { InfoCardSlider } from "@/components/home/info-card-slider";
import { HomeReviewSlider } from "@/components/home/home-review-slider";
import { InfoCardSections } from "./info-card-sections";

export class Home {
  init() {
    const mainSlider = new MainSlider();
    mainSlider.init();
    const infoCardSlider = new InfoCardSlider();
    infoCardSlider.init();
    const homeReviewSlider = new HomeReviewSlider();
    homeReviewSlider.init();

    InfoCardSections.init();
  }
}

const itemQuery = document.getElementById('query');
if (itemQuery != null) {
  // let position = itemQuery.getBoundingClientRect();
  document.onload = window.scrollTo(72, 295);
}
