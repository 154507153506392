export class Pictures {
  /**
   *
   * @type {[{element: Element, active: boolean}]}
   */
  pictures = [];
  /**
   *
   * @type {number}
   */
  timeoutAdd = 1000;
  init() {
    document.querySelectorAll('.picture').forEach((el) => {
      this.pictures.push({ element: el, active: false });
    });

    document.addEventListener('scroll', (e) => {
      this.pictures.forEach((el) => {
        this.checkElement(e, el);
      });
    });
  }

  /**
   *
   * @param event {Event}
   * @param picture {{element: Element, active: boolean}}
   */
  checkElement(event, picture) {
    if (picture.active) return;
    const { top, bottom } = picture.element.getBoundingClientRect();
    if (top < window.innerHeight && bottom >= 0) {
      picture.active = true;
      this.animate(picture.element);
    }
  }

  /**
   *
   * @param element {Element}
   */
  animate(element) {
    let timeout = 0;
    const elements = [];
    elements.push(element.querySelector('.picture-first'));
    elements.push(element.querySelector('.picture-second'));
    elements.push(element.querySelector('.picture-third'));
    elements.push(element.querySelector('.picture-fourth'));
    elements.push(element.querySelector('.picture-fifth'));

    elements.forEach((el) => {
      timeout = this.showElement(timeout, el);
    });
  }

  /**
   *
   * @param timeout {number}
   * @param element {SVGElement}
   * @returns {number}
   */
  showElement(timeout, element) {
    if (element) {
      timeout += parseInt(element.dataset.timeout);
      setTimeout(() => {
        element.classList.add('show');
      }, timeout);
    }

    return timeout;
  }
}
