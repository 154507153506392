import AirDatepicker from 'air-datepicker';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { dateFormat, dateParse, getJSON } from '@/modules/functions';
import { loadingHide, loadingShow } from '@/components/dashboard/loading';

class DashboardChart {
  /**
   * @type AirDatepicker
   */
  chartDatepicker;
  /**
   * @type HTMLElement
   */
  dashboardTitle;
  /**
   * @type DashboardChart
   */
  chart;
  /**
   *
   * @type {string}
   */
  chartQuery;
  /**
   *
   * @type {string}
   */
  chartDateStart;
  /**
   *
   * @type {string}
   */
  chartDateEnd;
  /**
   * @type {HTMLElement}
   */
  loadingDashboardChart;
  /**
   *
   * @type {boolean}
   */
  chartInitiated = false;
  /**
   *
   * @type {ChartData[]}
   */
  chartData = [];

  init() {
    this.dashboardTitle = document.querySelector('.dashboard-title');

    this.loadingDashboardChart = document.querySelector(
      '.loading-dashboard-chart'
    );

    document.querySelector('.dashboard').style.display = 'none';
    loadingHide(this.loadingDashboardChart);
  }

  chartDatePickerInit() {
    const datepicker = document.querySelector('.dashboard-datepicker');
    if (!datepicker) {
      return;
    }

    this.chartDatepicker = new AirDatepicker(datepicker, {
      dateFormat: 'MMM dd, yyyy',
      range: true,
      multipleDatesSeparator: '-',
      autoClose: true,
      minDate: dateParse(this.chartDateStart),
      maxDate: dateParse(this.chartDateEnd),
      buttons: ['clear'],
      selectedDates: [
        dateParse(this.chartDateStart),
        dateParse(this.chartDateEnd),
      ],
      onShow: () => {
        this.chartDatepicker.$datepicker.style.width =
          datepicker.clientWidth + 'px';
      },
      onSelect: (data) => {
        if (data.date.length === 2) {
          this.chartDateStart = dateFormat(data.date[0]);
          this.chartDateEnd = dateFormat(data.date[1]);
          this.chartGet(true);
        } else if (data.date.length === 0) {
          this.chartDateStart = undefined;
          this.chartDateEnd = undefined;
          this.chartGet(true);
        }
      },
    });
  }

  chartInit() {
    const chart = document.querySelector('#dashboard-chart');
    if (!chart) {
      return;
    }

    const optionScales = {
      grid: {
        display: false,
      },
      border: {
        width: 2,
        color: '#f78172',
        z: 1,
      },
      ticks: {
        color: '#7d7d7d',
        font: {
          size: 16,
          family: "'Rubik', sans-serif",
        },
      },
    };

    this.chart = new Chart(chart, {
      type: 'bar',
      data: {
        labels: this.chartData.map((row) => row.date),
        datasets: [
          {
            label: 'Количество запросов',
            data: this.chartData.map((row) => row.count),
            backgroundColor: ['#ffe9e9'],
          },
        ],
      },
      options: {
        plugins: {
          legend: {
            display: false,
          },
          datalabels: {
            color: '#f78172',
            font: {
              size: 22,
              family: "'Rubik', sans-serif",
              weight: 500,
            },
            anchor: 'end',
            align: 'top',
          },
        },
        scales: {
          y: optionScales,
          x: optionScales,
        },
        layout: {
          padding: {
            top: 40,
          },
        },
      },
      plugins: [ChartDataLabels],
    });
  }

  /**
   *
   * @param data {ChartRequestData}
   */
  chartSetData(data) {
    this.chartQuery = data.query;
    this.chartDateStart = data.dateStart;
    this.chartDateEnd = data.dateEnd;
  }

  /**
   *
   * @param [changeData] {boolean}
   */
  chartGet(changeData = false) {
    /* todo Заменить на нужную ссылку на получение данных таблицы */
    const url = new URL(
      `${window.location.origin}/search_dashboard`
    );

    this.chartQuery && url.searchParams.append('query', this.chartQuery);
    this.chartDateStart &&
      url.searchParams.append('dateStart', this.chartDateStart);
    this.chartDateEnd && url.searchParams.append('dateEnd', this.chartDateEnd);

    if (!this.chartInitiated) {
      loadingShow(this.loadingDashboardChart);
    }

    getJSON(url, (status, responseData) => {
      if (status === 200) {
        this.chartData = responseData;

        if (!this.chartInitiated) {
          this.chartDatePickerInit();
          this.chartInit();
          this.chartInitiated = true;
          document.querySelector('.dashboard').style.display = 'block';
          loadingHide(this.loadingDashboardChart);
        } else {
          this.chartUpdate();
          if (!changeData) {
            this.chartDatePickerUpdate();
          }
        }

        this.dashboardTitle.textContent = this.chartQuery;
      } else {
        console.error('Ошибка при выполнении запроса.');
      }
    });
  }
  chartUpdate() {
    this.chart.data = {
      labels: this.chartData.map((d) => d.date),
      datasets: [
        {
          label: 'Количество запросов',
          data: this.chartData.map((row) => row.count),
          backgroundColor: ['#ffe9e9'],
        },
      ],
    };
    this.chart.update();
  }

  chartDatePickerUpdate() {
    this.chartDatepicker.update({
      minDate: dateParse(this.chartDateStart),
      maxDate: dateParse(this.chartDateEnd),
    });
    this.chartDatepicker.selectDate([
      dateParse(this.chartDateStart),
      dateParse(this.chartDateEnd),
    ]);
  }
}

export const dashboardChart = new DashboardChart();

/**
 * Объект полученной графика
 * @typedef {Object} ChartRequestData
 * @property {string} query - Название запроса
 * @property {string} dateStart - Начальная дата в формате 22.02.2024
 * @property {string} dateEnd - Завершающая дата в формате 22.03.2024
 */

/**
 * Объект полученной графика
 * @typedef {Object} ChartData
 * @property {string} date - Дата в формате 10 января 2024
 * @property {number} count - Кол-во записей
 */
