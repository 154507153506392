import { article01 } from '@/components/articles/article-01';
import { article06 } from '@/components/articles/article-06';
import { articleUlAnimate } from '@/components/articles/article-ul-animate';
// import { allArticles } from '@/components/articles/all-articles';
import { article07 } from '@/components/articles/article-07';
import { MasonryGridFilters } from '@/components/masonry-grid-filters';
import { article10 } from '@/components/articles/article-10';
import { article12 } from '@/components/articles/article-12';

class Articles {
  init() {
    const allArticlesPage = document.querySelector('.all-articles-page');
    if (allArticlesPage) {
      const masonryGridFilter = new MasonryGridFilters();
      masonryGridFilter.init('#filters-form');
    }

    article01.init();
    article06.init();

    const article07Page = document.querySelector('.article-07-application');
    if (article07Page) {
      article07.init();
    }

    const article = document.querySelector('.article-container');

    const article10Page = document.querySelector('.article-10');
    if (article10Page) {
      article10.init();
    }

    const article12Page = document.querySelector('.article-12');
    if (article12Page) {
      article12.init();
    }

    if (article) {
      articleUlAnimate.init();
    }
  }
}

export const articles = new Articles();
