import { Autoplay, Swiper } from 'swiper';

class FooterLogoSlider {
  init() {
    Swiper.use([Autoplay]);

    const slider = document.querySelector('.footer-logo-slider');
    if (!slider) return;

    const slideLength = slider.querySelectorAll('.footer-logo-item').length;

    const swiper = new Swiper(slider, {
      slidesPerView: 'auto',
      speed: 3000,
      loopedSlides: slideLength,
      spaceBetween: 20,
      centeredSlides: true,
      loop: true,
      autoplay: {
        delay: 0,
      },
    });
  }
}

export const footerLogoSlider = new FooterLogoSlider();
