import { MasonryGridFilters } from '@/components/masonry-grid-filters';

class Specialists {
  init() {
    const specialists = document.querySelector('.specialists-page');
    if (specialists) {
      const masonryGridFilter = new MasonryGridFilters();
      masonryGridFilter.init('#filters-form');
    }
  }
}

export const specialists = new Specialists();
