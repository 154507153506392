import { slideDown } from '@/modules/functions';

class Article06All {
  init() {
    let list = document.querySelectorAll('.article-06-content-tab');

    list.forEach(function (element) {
      let btn = element.querySelector('.article-06-content-list-all');
      let item = element.querySelector('.article-06-content-list.hidden');
      btn.addEventListener('click', () => {
        slideDown(item, 1000, 'flex');
        btn.remove();
      });
    });
  }
}

export const article06All = new Article06All();
