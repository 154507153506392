import { getHTML, isTablet, slideToggle } from "@/modules/functions";
import Masonry from "masonry-layout";
import { SecondFiltersHead } from "@/components/second-filters-head";

export class MasonryGridFilters {
  /**
   * @type {HTMLFormElement}
   */
  form;
  masonry;
  /**
   * @type {HTMLElement}
   */
  masonryGrid;
  /**
   * @type {string}
   */
  url;

  /**
   *
   * @param formSelector {string}
   */
  init(formSelector) {
    this.filtersInit(formSelector);
    this.sortingInit();
    this.loadMoreInit();
    this.masonryInit();
  }

  /**
   *
   * @param formSelector {string}
   */
  filtersInit(formSelector) {
    this.form = document.querySelector(formSelector);
    const filters = new SecondFiltersHead();

    filters.init(() => {
      this.formChange();
    }, this.form);

    this.url = this.form.action;
  }

  sortingInit() {
    const columns = document.querySelectorAll(".masonry-grid-sorting-col");
    columns.forEach((el) => {
      const radioList = el.querySelectorAll("input[type=radio]");
      if (radioList.length) {
        el.onclick = () => {
          const { hasActive, activeElement, inactiveElement } =
            this.checkActiveSortingRadio(radioList);
          if (hasActive) {
            activeElement.checked = false;
            inactiveElement.checked = true;
            if (el.classList.contains("invert")) {
              el.classList.remove("invert");
            } else {
              el.classList.add("invert");
            }
          } else {
            columns.forEach((el) => {
              el.classList.remove(...["active", "invert"]);
            });
            el.classList.add("active");
            radioList.item(0).checked = true;
          }

          this.formChange();
        };
      }
    });

    if (isTablet()) {
      this.sortingResponsiveInit();
    }
  }

  sortingResponsiveInit() {
    const title = document.querySelector(".masonry-grid-sorting-title");
    const list = document.querySelector(".masonry-grid-sorting-list");
    title.onclick = (e) => {
      e.preventDefault();
      title.classList.toggle("active");
      slideToggle(list);
    };
  }

  /**
   *
   * @param radioList {NodeListOf<HTMLInputElement>}
   * @returns {{hasActive: boolean, activeElement: HTMLInputElement, inactiveElement: HTMLInputElement}}
   */
  checkActiveSortingRadio(radioList) {
    let hasActive = false;
    let activeElement;
    let inactiveElement;
    radioList.forEach((el) => {
      if (el.checked) {
        hasActive = true;
        activeElement = el;
      } else {
        inactiveElement = el;
      }
    });

    return { hasActive, activeElement, inactiveElement };
  }

  loadMoreInit() {
    const button = document.querySelector(".button-load-more");
    if (button) {
      button.onclick = () => {
        this.formChange(true);
      };
    }
  }

  /**
   *
   * @param loadMore {boolean}
   */
  formChange(loadMore = false) {
    const data = new FormData(this.form);

    if (loadMore)
      data.append(
        "page",
        parseInt(document.querySelector(".button-load-more").dataset.page)
      );

    const queryString = new URLSearchParams(data).toString();

    fetch(`${this.form.action}?${queryString}`)
      .then((data) => {
        return data.text();
      })
      .then((text) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(text, "text/html");
        if (doc.querySelector(".button-load-more")) {
          document.querySelector(
            ".button-load-more"
          ).parentElement.style.display = "block";
          document.querySelector(".button-load-more").dataset.page =
            doc.querySelector(".button-load-more").dataset.page;
        }

        if (!loadMore) {
          const items = this.masonryGrid.querySelectorAll(".masonry-grid-card");
          this.masonry.remove(items);
          this.masonry.layout();
        } else {
          if (!doc.querySelector(".button-load-more")) {
            document.querySelector(
              ".button-load-more"
            ).parentElement.style.display = "none";
          }
        }
        doc.querySelectorAll(".masonry-grid-card").forEach((el) => {
          this.masonryGrid.appendChild(el);
          this.masonry.appended(el);
          this.masonry.layout();
          // console.log(el);
        });
      });
  }

  masonryInit() {
    this.masonryGrid = document.querySelector(".masonry-grid-list");
    this.masonry = new Masonry(this.masonryGrid, {
      columnWidth: ".masonry-grid-card-sizer",
      itemSelector: ".masonry-grid-card",
      percentPosition: true,
      gutter: 30,
    });
  }
}
