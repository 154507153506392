export class YoutubeVideo {
  /**
   * @type HTMLIFrameElement
   */
  playedVideo;
  /**
   * @type HTMLElement
   */
  playedVideoCover;
  constructor() {}

  /**
   *
   * @param iframe {HTMLIFrameElement}
   * @param cover {HTMLElement}
   */
  playVideo(iframe, cover) {
    if (this.playedVideo) {
      this.stopVideo();
    }
    this.playedVideoCover = cover;
    this.playedVideo = iframe;
    cover.style.opacity = '0';
    setTimeout(() => {
      cover.style.display = 'none';
      iframe.contentWindow.postMessage(
        '{"event":"command","func":"' + 'playVideo' + '","args":""}',
        '*'
      );
    }, 300);
  }

  stopVideo() {
    this.playedVideoCover.style.display = 'block';
    this.playedVideoCover.style.opacity = '1';
    this.playedVideo.contentWindow.postMessage(
      '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
      '*'
    );
  }
}
