import { slideDown, slideUp } from '@/modules/functions';

class Article06Tab {
  init() {
    let tabsBtn = document.querySelectorAll('.article-06-card');
    let tabsItem = document.querySelectorAll('.article-06-content-tab');
    let items = document.querySelectorAll('.article-06-content-item');

    let prevPath = '';

    tabsBtn.forEach(function (element) {
      element.addEventListener('click', function (e) {
        const path = e.currentTarget.dataset.path;
        const itemActive = document.querySelector(`[data-target="${path}"]`);

        tabsBtn.forEach(function (btn) {
          btn.classList.remove('active');
        });
        e.currentTarget.classList.add('active');

        tabsItem.forEach(function (item) {
          item.classList.remove('active');
          if (item !== itemActive) slideUp(item, 0);
        });

        if (prevPath != path) slideDown(itemActive, 1800);
        prevPath = path;
      });
    });
  }
}

export const article06Tab = new Article06Tab();

// Как здесь обратиться к предыдущему элементу, по которму был произведён клик?
