/**
 *
 * @param {string} path
 * @returns {string}
 */
export function addSlashToPath(path) {
  let newPath = path;

  if (newPath.slice(-1) !== '/') {
    newPath += '/';
  }

  return newPath;
}

/**
 *
 * @returns {boolean}
 */
export function isMobile() {
  return window.innerWidth < 768;
}

/**
 *
 * @returns {boolean}
 */
export function isTablet() {
  return window.innerWidth < 992;
}

/**
 * Функция для обертки элемента в другой элемент с заданым классом
 * @param {Element} el
 * @param {string} wrapClass
 */

export function wrap(el, wrapClass = '') {
  const wrapper = document.createElement('div');
  wrapper.classList.add(wrapClass);
  el.parentNode.insertBefore(wrapper, el);
  wrapper.appendChild(el);
}

/**
 * Функция генерации рандомных чисел между мин. и макс. значениями
 * @param {number} min
 * @param {number} max
 * @returns {number}
 */
export function randomInteger(min, max) {
  let rand = min - 0.5 + Math.random() * (max - min + 1);
  return Math.round(rand);
}

/**
 *
 * @param {string | URL} url
 * @param {requestCallback} callback
 */

export function getJSON(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'json';
  xhr.onload = () => {
    const status = xhr.status;
    callback(status, xhr.response);
  };
  xhr.send();
}

/**
 * This callback is displayed as a global member.
 * @callback requestCallback
 * @param {number} responseCode
 * @param responseData
 */

/**
 *
 * @param {string | URL} url
 * @param {requestCallback} callback
 */

export function getHTML(url, callback) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'document';
  xhr.onload = () => {
    const status = xhr.status;
    callback(status, xhr.response);
  };
  xhr.send();
}

/**
 * This callback is displayed as a global member.
 * @callback requestCallback
 * @param {number} responseCode
 * @param responseData
 */

/**
 *
 * @param {number} value
 * @returns {string}
 */
export function addZero(value) {
  let newValue = '' + value;
  if (value < 10) {
    newValue = '0' + newValue;
  }

  return newValue;
}

/**
 *
 * @param {HTMLElement} target
 * @param {number} duration
 */

export function slideUp(target, duration = 500) {
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.boxSizing = 'border-box';
  target.style.height = target.offsetHeight + 'px';
  target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout(() => {
    target.style.display = 'none';
    target.style.removeProperty('height');
    target.style.removeProperty('padding-top');
    target.style.removeProperty('padding-bottom');
    target.style.removeProperty('margin-top');
    target.style.removeProperty('margin-bottom');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
}

/**
 *
 * @param {HTMLElement} target
 * @param {number} duration
 * @param {string} styleDisplay
 */

export function slideDown(target, duration = 500, styleDisplay = 'block') {
  target.style.removeProperty('display');
  let display = window.getComputedStyle(target).display;

  if (display === 'none') display = styleDisplay;

  target.style.display = display;
  let height = target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight;
  target.style.boxSizing = 'border-box';
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.height = height + 'px';
  target.style.removeProperty('padding-top');
  target.style.removeProperty('padding-bottom');
  target.style.removeProperty('margin-top');
  target.style.removeProperty('margin-bottom');
  window.setTimeout(() => {
    target.style.removeProperty('height');
    target.style.removeProperty('overflow');
    target.style.removeProperty('transition-duration');
    target.style.removeProperty('transition-property');
  }, duration);
}

/**
 *
 * @param {HTMLElement} target
 * @param {number} duration
 */

export function slideToggle(target, duration = 500) {
  if (window.getComputedStyle(target).display === 'none') {
    return slideDown(target, duration);
  } else {
    return slideUp(target, duration);
  }
}

/**
 *
 * @param {string | URL} url
 * @param {XMLHttpRequestResponseType} responseType
 * @param {string} contentType
 * @param {requestCallback} callback
 */

export function requestGET(url, responseType, contentType, callback) {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.setRequestHeader('Content-Type', contentType);
  if (responseType) {
    xhr.responseType = responseType;
  }
  xhr.onload = () => {
    const status = xhr.status;
    callback(status, xhr);
  };
  xhr.send();
}

/**
 * This callback is displayed as a global member.
 * @callback requestCallback
 * @param {number} responseCode
 * @param responseData
 */

/**
 *
 * @param date {string}
 * @return {Date}
 */
export function dateParse(date) {
  const parts = date.split('.');
  return new Date(`${parts[2]}-${parts[1]}-${parts[0]}`);
}

/**
 *
 * @param date {Date}
 * @return {string}
 */
export function dateFormat(date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}
