class ArticleUlAnimate {
  init() {
    document.querySelectorAll('.article-container ul').forEach((el) => {
      const allLi = el.querySelectorAll('li');
      allLi.forEach((el, i) => {
        el.classList.add('animate', 'animate__fadeInUp');
        if (allLi.length > 10) {
          el.dataset.wowDelay = ((i + 1) * 0.1).toFixed(1) + 's';
        } else {
          el.dataset.wowDelay = ((i + 1) * 0.2).toFixed(1) + 's';
        }
      });
    });
  }
}

export const articleUlAnimate = new ArticleUlAnimate();
