import { dateFormat, dateParse, getJSON } from '@/modules/functions';
import { loadingHide, loadingShow } from '@/components/dashboard/loading';
import { dashboardChart } from '@/components/dashboard/dashboard-chart';
import AirDatepicker from 'air-datepicker';

class DashboardTable {
  /**
   * @type {HTMLElement}
   */
  table;
  /**
   *
   * @type {number}
   */
  tableCount = 10;
  /**
   *
   * @type {'desc' | 'asc'}
   */
  tableOrder = 'asc';
  /**
   *
   * @type {'query' | 'count'}
   */
  tableColumn = 'query';
  /**
   *
   * @type {string}
   */
  tableDateStart;
  /**
   *
   * @type {string}
   */
  tableDateEnd;
  /**
   * @type AirDatepicker
   */
  tableDatepicker;
  /**
   * @type {HTMLElement}
   */
  loadingDashboardTable;

  init() {
    this.table = document.querySelector('.dashboard-table');
    if (!this.table) {
      return;
    }

    this.loadingDashboardTable = document.querySelector(
      '.loading-dashboard-table'
    );

    this.tableHeadInit();
    this.tableLoadMoreInit();
    this.tableDatePickerInit();
    this.tableGet();
  }

  tableHeadInit() {
    this.table.querySelectorAll('th button').forEach((el, i) => {
      el.onclick = () => {
        const active = el.classList.contains('active');
        const invert = el.classList.contains('invert');
        if (active) {
          if (invert) {
            el.classList.remove('invert');
            this.tableOrder = 'asc';
          } else {
            el.classList.add('invert');
            this.tableOrder = 'desc';
          }
        } else {
          this.table
            .querySelector('th button.active')
            ?.classList.remove('active', 'invert');
          el.classList.add('active');
          this.tableOrder = 'asc';
        }

        this.tableColumn = el.dataset.sort;

        this.tableGet();
      };
    });
  }

  tableBodyInit() {
    this.table.querySelectorAll('tbody tr').forEach((el) => {
      el.onclick = () => {
        const tds = el.querySelectorAll('td');
        const date = tds.item(2).textContent;
        const parts = date.split('-');

        dashboardChart.chartSetData({
          query: tds.item(0).textContent,
          dateStart: parts[0],
          dateEnd: parts[1],
        });
        dashboardChart.chartGet();
      };
    });
  }

  tableLoadMoreInit() {
    const button = document.querySelector('.table-load-more');
    if (!button) return;

    button.onclick = (e) => {
      e.preventDefault();
      this.tableLoadMore();
    };
  }

  tableDatePickerInit() {
    const datepicker = document.querySelector('.dashboard-table-date-input');
    if (!datepicker) {
      return;
    }

    this.tableDatepicker = new AirDatepicker(datepicker, {
      dateFormat: 'MMM dd, yyyy',
      range: true,
      multipleDatesSeparator: '-',
      autoClose: true,
      buttons: ['clear'],
      toggleSelected: false,
      onSelect: ({ date }) => {
        if (date.length === 2) {
          this.tableDateStart = dateFormat(date[0]);
          this.tableDateEnd = dateFormat(date[1]);
          this.tableGet(true);
        } else {
          this.tableDateStart = undefined;
          this.tableDateEnd = undefined;
          this.tableGet(true);
        }
      },
    });
  }

  tableGet() {
    /* todo Заменить на нужную ссылку на получение данных таблицы */
    const url = new URL(
      `${window.location.origin}/search_table`
    );

    this.tableCount &&
      url.searchParams.append('count', this.tableCount.toString(10));
    this.tableColumn && url.searchParams.append('column', this.tableColumn);
    this.tableOrder && url.searchParams.append('order', this.tableOrder);
    this.tableDateStart &&
      url.searchParams.append('dateStart', this.tableDateStart);
    this.tableDateEnd && url.searchParams.append('dateEnd', this.tableDateEnd);

    loadingShow(this.loadingDashboardTable);

    getJSON(url, (status, responseData) => {
      if (status === 200) {
        this.tableUpdate(responseData);
        loadingHide(this.loadingDashboardTable);
      } else {
        console.error('Ошибка при выполнении запроса.');
      }
    });
  }

  /**
   *
   * @param data {TableData[]}
   */
  tableUpdate(data) {
    const tbody = document.querySelector('.dashboard-table tbody');
    tbody.innerHTML = '';
    data.forEach((item) => {
      const tr = document.createElement('tr');
      Object.values(item).forEach((value) => {
        const td = document.createElement('td');
        td.textContent = value;
        tr.appendChild(td);
      });
      tbody.appendChild(tr);
    });

    this.tableBodyInit();
  }

  tableLoadMore() {
    this.tableCount += 10;
    this.tableGet();
  }
}

export const dashboardTable = new DashboardTable();

/**
 * Объект полученной таблицы
 * @typedef {Object} TableData
 * @property {string} name - Название запроса
 * @property {number} count - Кол-во запросов
 * @property {string} date - Диапазон дат в формате 22.02.2024-22.03.2024
 */
