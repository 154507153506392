import { requestGET } from '@/modules/functions';

import { YoutubeVideo } from '@/components/youtube-video';

class VideoAnswers extends YoutubeVideo {
  constructor() {
    super();
  }
  init() {
    if (!document.querySelector('.video-answers-page')) {
      return;
    }
    const main = document.querySelector('.video-answers-main-video');
    if (!main) {
      return;
    }
    const mainCover = main?.querySelector('.video-answers-main-video-cover');
    if (mainCover) {
      mainCover.onclick = () => {
        this.playVideo(
          main.querySelector('.video-answers-main-video-iframe'),
          mainCover
        );
      };
    }

    document.querySelectorAll('.video-answers-item').forEach((el) => {
      const cover = el.querySelector('.video-answers-item-video-cover');
      cover.onclick = () => {
        this.playVideo(
          el.querySelector('.video-answers-item-video-iframe'),
          cover
        );
      };
    });

    this.initLoadMore();
  }

  initLoadMore() {
    // const button = document.querySelector('.video-answers-more-button');
    // if (!button) {
    //   return;
    // }

    // button.onclick = (e) => {
    //   e.preventDefault();
    //   this.loadMore();
    // };
  }

  loadMore() {
    if (this.loading) {
      return;
    }

    const params = {
      page: '2'
    };

    const url = new URL('http://localhost:3000/video-otvety');
    url.search = new URLSearchParams(params).toString();
    this.loading = true;

    requestGET(
      url,
      '',
      'text/javascript; charset=utf-8',
      (statusCode, response) => {
        this.loading = false;
        // console.warn(response);
        if (statusCode === 200) {
          /** todo something if success */
        } else {
          /** todo something if error */
        }
      }
    );
  }
}

export const videoAnswers = new VideoAnswers();
