import { SecondFiltersHead } from "@/components/second-filters-head";

export class SecondFilters {
  /**
   * @type {HTMLFormElement}
   */
  form;
  /**
   * @type {string}
   */
  url;

  /**
   *
   * @param formSelector {string}
   */
  init(formSelector, listSelector, itemSelector) {
    this.filtersInit(formSelector, listSelector, itemSelector);
    this.loadMoreInit();
  }

  /**
   *
   * @param formSelector {string}
   */
  filtersInit(formSelector, listSelector, itemSelector) {
    this.form = document.querySelector(formSelector);
    this.list = document.querySelector(listSelector);
    this.itemQuery = itemSelector;
    const filters = new SecondFiltersHead();

    filters.init(() => {
      this.formChange();
    }, this.form);

    this.url = this.form.action;
  }

  loadMoreInit() {
    const button = document.querySelector(".load-more-button");
    if (!button) return;
    button.onclick = () => {
      this.formChange(true);
    };
  }

  /**
   *
   * @param loadMore {boolean}
   */
  formChange(loadMore = false) {
    const data = new FormData(this.form);

    if (loadMore)
      data.append(
        "page",
        parseInt(document.querySelector(".button-load-more").dataset.page)
      );

    const queryString = new URLSearchParams(data).toString();

    fetch(`${this.form.action}?${queryString}`)
      .then((data) => {
        return data.text();
      })
      .then((text) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(text, "text/html");
        if (doc.querySelector(".button-load-more")) {
          document.querySelector(
            ".button-load-more"
          ).parentElement.style.display = "block";
          document.querySelector(".button-load-more").dataset.page =
            doc.querySelector(".button-load-more").dataset.page;
        }

        if (!loadMore) this.list.innerHTML = "";
        else {
          if (!doc.querySelector(".button-load-more")) {
            document.querySelector(
              ".button-load-more"
            ).parentElement.style.display = "none";
          }
        }
        doc.querySelectorAll(this.itemQuery).forEach((el) => {
          this.list.appendChild(el);
          // console.log(el);
        });
      });
  }
}
