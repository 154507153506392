import 'simplebar';
import ResizeObserver from 'resize-observer-polyfill';
import SimpleBar from 'simplebar';
import { cabinetsMap } from '@/components/cabinets/cabinets-map';
window.ResizeObserver = ResizeObserver;
class Cabinets {
  /**
   * @type {HTMLElement}
   */
  cabinetsPage;
  init() {
    this.cabinetsPage = document.querySelector('.cabinets-page');
    if (!this.cabinetsPage) return;

    this.overflowInit();
    cabinetsMap.init();
  }

  overflowInit() {
    const overflow = this.cabinetsPage.querySelector(
      '.cabinets-map-list-overflow'
    );

    new SimpleBar(overflow, { autoHide: false });
  }
}

export const cabinets = new Cabinets();
