export class SecondFiltersHead {
  /**
   * @type {HTMLFormElement}
   */
  form;

  /**
   *
   * @param callback {filterCallback}
   * @param form {HTMLFormElement}
   */
  init(callback, form) {
    this.form = form;
    if (this.form) {
      document.querySelectorAll('.second-head-tags-item').forEach((el) => {
        el.addEventListener('click', (e) => {
          e.preventDefault();
          const input = el.querySelector('input');
          if(input.value == '') {
            document.querySelectorAll('.second-head-tags-item input').forEach((inpt) => inpt.checked = false);
            input.checked = true;
          } else {
            document.querySelector('.second-head-tags-item input[value=""]').checked = false;
            input.checked = !input.checked;
          }

          callback();
        });
      });
    }
  }
}

/**
 * This callback is displayed as a global member.
 * @callback filterCallback
 */
