export class InfoCardSections {
  static init() {
    document
      .querySelectorAll(".home-info-card-sections[data-tag]")
      .forEach((el) => {
        fetch(`/tags?tag=${el.dataset.tag}`)
          .then((data) => data.text())
          .then((text) => (el.innerHTML = text));
      });
  }
}
