import { SecondFilters } from '@/components';

class Files {
  init() {
    const files = document.querySelector('.files-page');
    if (files) {
      const filters = new SecondFilters();
      filters.init('#filters-form', '.files-content .container .files-list', '.files-item');
    }
  }
}

export const files = new Files();
