import { isTablet } from '@/modules/functions';

export class Header {
  /**
   * @type HTMLElement
   */
  header;
  /**
   * @type HTMLElement
   */
  headerSearch;
  headerHideTimeout;

  init() {
    this.header = document.querySelector('.header');
    this.headerSearch = document.querySelector('.header-search');

    this.headerScroll();
    this.headerSearchInit();

    this.header.addEventListener('mouseenter', () => {
      this.header.classList.add('hovered');
    });

    this.header.addEventListener('mouseleave', () => {
      this.header.classList.remove('hovered');
      if (window.scrollY > 180 && !isTablet()) {
        this.checkHeaderScroll();
      }
      if (isTablet() && window.scrollY > 0) {
        this.header.classList.add('fixed');
      }
    });
  }

  headerScroll() {
    window.addEventListener('scroll', () => {
      if (!isTablet()) {
        if (window.scrollY > 180) {
          this.checkHeaderScroll();
        } else {
          this.header.classList.remove('fixed');
          this.header.classList.remove('hide');
        }
      } else {
        if (window.scrollY > 0) {
          this.header.classList.add('fixed');
        } else {
          this.header.classList.remove('fixed');
        }
      }
    });
  }

  checkHeaderScroll() {
    clearTimeout(this.headerHideTimeout);
    this.header.classList.remove('hide');
    this.header.classList.add('fixed');
    this.headerHideTimeout = setTimeout(() => {
      if (!this.header.classList.contains('hovered')) {
        this.header.classList.add('hide');
      }
    }, 2000);
  }

  headerSearchInit() {
    document.querySelectorAll('.header-search-button').forEach((el) => {
      el.onclick = () => {
        this.header.classList.add('show-search');
      };
    });

    const control = document.querySelector('.header-search-control');

    control.onblur = () => {
      if (control.value && control.value.length > 0) {
        control.classList.add('filled');
      } else {
        control.classList.remove('filled');
      }
    };

    document.querySelector('.header-search-close').onclick = () => {
      this.header.classList.remove('show-search');
    };
  }
}
