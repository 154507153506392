import Rails from "@rails/ujs";

import { Burger, Header, Popup, RefererPopup } from "@/components";
import { Home } from "@/components/home";
import { animate } from "@/modules/wow";
import { articles } from "@/components/articles";
import { faq } from "@/components/faq";
import { files } from "@/components/files";
import { footerLogoSlider } from "@/components/footer-logo-slider";
import { specialists } from "@/components/specialists";
import { cabinets } from "@/components/cabinets";
import { videoAnswers } from '@/components/video-answers';
import { news } from '@/components/news';
import { dashboard } from '@/components/dashboard';

/* Global */
export const burger = new Burger();
export const popup = new Popup();
export const referer_popup = new RefererPopup();
export const home = new Home();
export const header = new Header();

window.addEventListener("DOMContentLoaded", () => {
  init();
});

function init() {
  /**
   * Global
   */
  Rails.start();
  burger.init();
  popup.init();
  referer_popup.init();
  header.init();
  footerLogoSlider.init();

  /**
   * Pages
   */
  home.init();
  articles.init();
  faq.init();
  files.init();
  specialists.init();
  cabinets.init();
  videoAnswers.init();
  news.init();
  dashboard.init();

  animate.init();
}
