import { article06Tab } from '@/components/articles/article-06/article-06-tab';
import { article06All } from '@/components/articles/article-06/article-06-all';

class Article06 {
  init() {
    const article = document.querySelector('.article-06');
    if (!article) {
      return;
    }

    article06Tab.init();
    article06All.init();
  }
}

export const article06 = new Article06();
