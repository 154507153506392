import { slideToggle } from "@/modules/functions";

class FaqItem {
  init() {
    // document.querySelectorAll('.faq-item-head').forEach((el) => {
    //   el.onclick = () => {
    //     el.classList.toggle('active');
    //     const body = el.parentElement.querySelector('.faq-item-body');
    //     slideToggle(body);
    //   };
    // });

    document.addEventListener("click", someListener);
    function someListener(event) {
      var el = event.target;

      if (el.dataset.heigth != 'false') {
        console.log('srundefined', el.dataset.heigth);
        localStorage.setItem(
          'mnoportal-scroll',
          (window.scrollY - el.dataset.heigth).toString(10)
        );
      } else {
        localStorage.setItem(
          'mnoportal-scroll',
          window.scrollY.toString(10)
        );
      }

      // if (
      //   el.tagName == "DIV" &&
      //   (el.classList.contains("faq-item-head") ||
      //     el.classList.contains("faq-item-head-title") ||
      //     el.classList.contains("faq-item-head-plus"))
      // ) {
      //   if (
      //     el.classList.contains("faq-item-head-title") ||
      //     el.classList.contains("faq-item-head-plus")
      //   ) {
      //     el = el.closest(".faq-item-head");
      //   }
      //   el.classList.toggle("active");
      //   const body = el.parentElement.querySelector(".faq-item-body");
      //   slideToggle(body);
      // }
    }
  }
}

export const faqItem = new FaqItem();
