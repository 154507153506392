/**
 *
 * @param el {HTMLElement}
 */
export const loadingShow = (el) => {
  el.style.display = 'inherit';
};

/**
 *
 * @param el {HTMLElement}
 */
export const loadingHide = (el) => {
  el.style.display = 'none';
};
