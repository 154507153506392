import { getJSON, isTablet } from '@/modules/functions';
import { WHITE_MAP_STYLES } from '@/modules/map-style';

/**
 * @typedef {Object} MapItem
 * @property {number} id
 * @property {string} name
 * @property {string} city
 * @property {string} address
 * @property {string} phone
 * @property {number} lat
 * @property {number} lng
 * @property {boolean} disabled
 * @property {number} equal
 * @property {string} link
 */
class CabinetsMap {
  /**
   *
   * @type {MapItem[]}
   */
  mapData = [];
  /**
   * @type {HTMLElement}
   */
  linksContainer;
  /**
   * @type {HTMLElement}
   */
  mapContainer;
  /**
   * @type {HTMLElement}
   */
  navigation;
  /**
   * @type {google.maps.Map}
   */
  map;
  /**
   * @type {string}
   */
  markerIconLink;
  /**
   * @type {{[key: number]: google.maps.Marker}}
   */
  markersList;
  /**
   * @type {google.maps.LatLngBounds}
   */
  bounds;
  /**
   * @type {google.maps.LatLngBounds}
   */
  showedBounds;

  init() {
    this.linksContainer = document.querySelector('.simplebar-content');
    this.mapContainer = document.querySelector('#cabinets-map');
    this.navigation = document.querySelector('.cabinets-map-navigation');
    this.markerIconLink = `${location.origin}/map-point.png`;

    this.getJson();
    this.navigationInit();
  }

  navigationInit() {
    this.navigation
      .querySelectorAll('.cabinets-map-navigation-item')
      .forEach((el, i) => {
        if (i === 0) {
          this.navigationSetActive(el);
          el.onclick = () => {
            this.navigationSetActive(el);
            this.showAllMarkers();
            this.showAllLinks();
            this.map.fitBounds(this.bounds);
          };
        } else {
          el.onclick = () => {
            this.navigationSetActive(el);
            this.showMarkersByCity(el.dataset.city);
            this.showLinksByVisibleMarkers();
            this.map.fitBounds(this.showedBounds);
          };
        }
      });
  }

  getJson() {
    getJSON(`${location.origin}/cabinets.json`, (code, data) => {
      if (code === 200) {
        this.mapData = data.filter((el) => !el.disabled);
        this.mapInit();
      } else {
        throw new Error("Can't load json file");
      }
    });
  }

  mapInit() {
    const lat = parseFloat(this.mapContainer.dataset.lat);
    const lng = parseFloat(this.mapContainer.dataset.lng);
    const zoom = parseInt(this.mapContainer.dataset.zoom);

    const disableDefaultUI = isTablet();

    const pos = new google.maps.LatLng(lat, lng);
    this.map = new google.maps.Map(this.mapContainer, {
      zoom,
      center: pos,
      styles: WHITE_MAP_STYLES,
      disableDefaultUI,
    });

    this.bounds = new google.maps.LatLngBounds();

    this.mapGenerateMarkers();
    this.map.fitBounds(this.bounds);
  }

  mapGenerateMarkers() {
    this.markersList = {};
    this.mapData.forEach((mapItem) => {
      const linkItem = this.createListElement(mapItem);
      this.linksContainer.append(linkItem);

      const marker = this.createMarker(mapItem);
      this.markersList[mapItem.id] = marker;
      this.bounds.extend(marker.getPosition());
      this.mapInitMarker(marker, mapItem);
    });
  }

  /**
   *
   * @param marker {google.maps.Marker}
   * @param mapItem {MapItem}
   */
  mapInitMarker(marker, mapItem) {
    marker.addListener('click', () => {
      this.linksContainer
        .querySelectorAll('.cabinets-map-list-item')
        .forEach((el) => {
          const elId = parseInt(el.dataset.id);
          if (elId === mapItem.id || elId === mapItem.equal) {
            el.classList.remove('hide');
          } else {
            el.classList.add('hide');
          }
        });
    });
  }

  /**
   *
   * @param el {MapItem}
   * @returns {HTMLAnchorElement}
   */
  createListElement(el) {
    const a = document.createElement('a');
    a.classList.add('cabinets-map-list-item');
    a.href = el.link;
    a.target = '_blank';
    a.dataset.id = el.id.toString();

    if (el.name?.length) {
      const name = document.createElement('span');
      name.classList.add('cabinets-map-list-name');
      name.textContent = el.name;
      a.append(name);
    }

    if (el.address?.length) {
      const address = document.createElement('span');
      address.classList.add('cabinets-map-list-address');
      address.textContent = el.address;
      a.append(address);
    }

    if (el.phone?.length) {
      const phone = document.createElement('span');
      phone.classList.add('cabinets-map-list-phone');
      phone.textContent = el.phone;
      a.append(phone);
    }

    return a;
  }

  /**
   *
   * @param el {MapItem}
   * @returns {google.maps.Marker}
   */
  createMarker(el) {
    return new google.maps.Marker({
      position: new google.maps.LatLng(el.lat, el.lng),
      map: this.map,
      icon: this.markerIconLink,
    });
  }

  /**
   *
   * @param el {HTMLElement}
   */
  navigationSetActive(el) {
    if (el.classList.contains('active')) return;
    this.navigation.querySelector('.active')?.classList.remove('active');
    el.classList.add('active');
  }

  /**
   *
   * @param city {string}
   */
  showMarkersByCity(city) {
    this.showedBounds = new google.maps.LatLngBounds();
    this.mapData.forEach((mapItem) => {
      const marker = this.markersList[mapItem.id];
      marker.setVisible(mapItem.city === city);
      if (mapItem.city === city) {
        this.showedBounds.extend(marker.getPosition());
      }
    });
  }

  showAllMarkers() {
    Object.keys(this.markersList).forEach((key) => {
      this.markersList[key].setVisible(true);
    });
  }

  showLinksByVisibleMarkers() {
    this.linksContainer
      .querySelectorAll('.cabinets-map-list-item')
      .forEach((el) => {
        const elId = parseInt(el.dataset.id);
        const marker = this.markersList[elId];
        if (marker.getVisible()) {
          el.classList.remove('hide');
        } else {
          el.classList.add('hide');
        }
      });
  }

  showAllLinks() {
    this.linksContainer
      .querySelectorAll('.cabinets-map-list-item')
      .forEach((el) => {
        el.classList.remove('hide');
      });
  }
}

export const cabinetsMap = new CabinetsMap();
