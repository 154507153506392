import { dashboardTable } from '@/components/dashboard/dashboard-table';
import { dashboardChart } from '@/components/dashboard/dashboard-chart';

class Dashboard {
  init() {
    const dashboardPage = document.querySelector('.dashboard-page');
    if (!dashboardPage) {
      return;
    }
    dashboardTable.init();
    dashboardChart.init();
  }
}

export const dashboard = new Dashboard();
