import { EffectFade, Navigation, Pagination, Swiper } from 'swiper';
import Parallax from 'parallax-js/dist/parallax';

export class MainSlider {
  slider;
  timeoutIncrement = 300;
  timeoutArray = [];

  init() {
    if (!document.querySelector('.home-main')) return;
    this.sliderInit();
  }

  sliderInit() {
    Swiper.use([Navigation, Pagination, EffectFade]);
    this.slider = new Swiper('.home-main-slider', {
      slidesPerView: 1,
      loop: true,
      effect: 'fade',
      fadeEffect: {
        crossFade: true,
      },
      speed: 400,
      navigation: {
        prevEl: '.home-main-slider-prev',
        nextEl: '.home-main-slider-next',
      },
      pagination: {
        el: '.home-main-slider-pagination',
        clickable: true,
      },
      on: {
        slideChangeTransitionEnd: (swiper) => {
          this.animateOutSlide(swiper.slides[swiper.previousIndex]);
          this.animateInSlide(swiper.slides[swiper.activeIndex]);
        },
      },
    });

    this.parallax();
    this.showFirstSlide();
  }

  showFirstSlide() {
    const activeSlide = this.slider.slides[this.slider.activeIndex];
    this.animateInSlide(activeSlide);
  }

  cleatTimeout() {
    this.timeoutArray.map((item) => {
      clearTimeout(item);
    });
  }

  /**
   *
   * @param slide {HTMLElement}
   */
  animateInSlide(slide) {
    this.timeoutArray = [];
    let timeout = 0;

    slide.querySelector('.home-main-slider-title').classList.add('show');

    const children = slide.querySelector('.home-main-slider-head').children;

    Array.from(children).forEach((el, i) => {
      let saveTimeout;
      if (el.classList.contains('home-main-slider-list')) {
        el.querySelectorAll('li').forEach((li) => {
          saveTimeout = setTimeout(() => {
            li.classList.add('show');
          }, timeout);
          timeout += this.timeoutIncrement;
          this.timeoutArray.push(saveTimeout);
        });
      } else {
        saveTimeout = setTimeout(() => {
          el.classList.add('show');
        }, timeout);
        timeout += this.timeoutIncrement;
        this.timeoutArray.push(saveTimeout);
      }
    });

    const saveTimeout1 = setTimeout(() => {
      const parallax = slide.querySelector(
        '.home-main-slider-parallax-wrapper'
      );
      const image = slide.querySelector('.home-main-slider-image');
      if (parallax) {
        parallax.classList.add('show');
      } else if (image) {
        image.classList.add('show');
      }
    }, this.timeoutIncrement);

    this.timeoutArray.push(saveTimeout1);

    const saveTimeout2 = setTimeout(() => {
      slide.querySelector('.button').classList.add('show');
    }, timeout);

    this.timeoutArray.push(saveTimeout2);
  }

  animateOutSlide(slide) {
    this.cleatTimeout();
    const children = slide.querySelector('.home-main-slider-head').children;
    Array.from(children).forEach((el, i) => {
      if (el.classList.contains('home-main-slider-list')) {
        el.querySelectorAll('li').forEach((li) => {
          li.classList.remove('show');
        });
      } else {
        el.classList.remove('show');
      }
    });

    const parallax = slide.querySelector('.home-main-slider-parallax-wrapper');
    const image = slide.querySelector('.home-main-slider-image');
    if (parallax) {
      parallax.classList.remove('show');
    } else if (image) {
      image.classList.remove('show');
    }

    slide.querySelector('.button').classList.remove('show');
  }

  parallax() {
    document.querySelectorAll('.home-main-slider-parallax').forEach((el) => {
      new Parallax(el);
    });
  }
}
