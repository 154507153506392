import { article01Arrow } from '@/components/articles/article-01/article-01-arrow';

class Article01 {
  init() {
    const article = document.querySelector('.article-01');
    if (!article) {
      return;
    }

    article01Arrow.init();
  }
}

export const article01 = new Article01();
