import { Pictures } from '@/components/articles/pictures';

class Article07 {
  init() {
    const pictures = new Pictures();
    pictures.init();
  }
}

export const article07 = new Article07();
