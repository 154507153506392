import { faqItem } from '@/components/faq/faq-item';
import { SecondFilters } from '@/components';

class Faq {
  init() {
    const faq = document.querySelector('.faq-page');
    if (faq) {
      const filters = new SecondFilters();
      filters.init('#filters-form', '.faq-content .container .faq-list', '.faq-item');

      document.querySelectorAll('[data-heigth="true"]').forEach((el) => {
        el.setAttribute("data-heigth", document.getElementById("faq-item-body-show").clientHeight);
      });

      if (window.location.href.includes('faq') && (window.location.href.split('/').length > 4)) {
        const localStorageScroll = localStorage.getItem('mnoportal-scroll');

        if (localStorageScroll != null) {
          window.scrollTo({
            top: parseInt(localStorageScroll),
          });
        }

        window.addEventListener('scroll', () => {
          localStorage.setItem(
            'mnoportal-scroll',
            window.scrollY.toString(10)
          );
        });
      }

      const itemBody = document.getElementById("faq-item-body-show")

      if (itemBody != null) {
        const referer = itemBody.dataset.referer;

        if ((referer != undefined) && !referer.includes('mnoportal')) {
          const itemBody = document.getElementById('faq-item-body-show');
          let position = itemBody.getBoundingClientRect();

          window.scrollTo(position.left, position.top + window.scrollY - 244);
        }
      }

      faqItem.init();
    }
  }
}

export const faq = new Faq();
