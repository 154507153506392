import { Navigation, Swiper } from 'swiper';

export class HomeReviewSlider {
  slider;
  init() {
    if (!document.querySelector('.home-review-slider')) {
      return;
    }

    this.initSlider();
    this.initVideo();
  }

  initSlider() {
    Swiper.use([Navigation]);
    this.slider = new Swiper('.home-review-slider', {
      slidesPerView: 1,
      loop: true,
      on: {
        slideChange: (swiper) => {
          const videoCover = swiper.slides[swiper.previousIndex].querySelector(
            '.home-review-slider-video-cover'
          );

          if (videoCover) {
            videoCover.classList.remove('hide');
            swiper.slides[swiper.previousIndex]
              .querySelector('.home-review-slider-video-iframe')
              .contentWindow.postMessage(
                '{"event":"command","func":"' + 'stopVideo' + '","args":""}',
                '*'
              );
          }
        },
      },
      navigation: {
        prevEl: '.home-review-slider-prev',
        nextEl: '.home-review-slider-next',
      },
    });
  }

  initVideo() {
    document
      .querySelectorAll('.home-review-slider .home-review-slider-video')
      .forEach((el) => {
        const cover = el.querySelector('.home-review-slider-video-cover');
        cover.onclick = () => {
          cover.style.opacity = 0;
          setTimeout(() => {
            cover.classList.add('hide');
            cover.style.opacity = 1;
            el.querySelector(
              '.home-review-slider-video-iframe'
            ).contentWindow.postMessage(
              '{"event":"command","func":"' + 'playVideo' + '","args":""}',
              '*'
            );
          }, 300);
        };
      });
  }
}
