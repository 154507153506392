import { EffectFade, Navigation, Swiper } from 'swiper';

export class InfoCardSlider {
  slider;

  init() {
    if (!document.querySelector('.home-info')) return;
    this.sliderInit();
  }

  sliderInit() {
    Swiper.use([Navigation, EffectFade]);
    this.slider = new Swiper('.home-info-card-slider', {
      slidesPerView: 1,
      effect: 'fade',
      loop: true,
      fadeEffect: {
        crossFade: true,
      },
      navigation: {
        prevEl: '.home-info-card-slider-prev',
        nextEl: '.home-info-card-slider-next',
      },
    });
  }
}
