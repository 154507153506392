import { Pictures } from '@/components/articles/pictures';

class Article12 {
  init() {
    const pictures = new Pictures();
    pictures.init();
  }
}

export const article12 = new Article12();
