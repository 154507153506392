export class RefererPopup {
  /**
   * @private
   * @type {number}
   */
  animationDuration = 600;
  /**
   * @private
   * @type {HTMLElement}
   */
  body = null;

  init() {
    this.body = document.querySelector('body');
    this.openInit();
    this.closeInit();
  }

  /**
   * @private
   */
  openInit() {
    if (document.querySelector('[data-referer]') !== null ) {
      if (document.querySelector('[data-referer]').dataset['referer'] == 'false') {
        let popup;
        popup = document.getElementById('referer-specialists');
        this.open(popup);
      };
    };
  }

  /**
   * @private
   */
  closeInit() {
    document.addEventListener('click', (e) => {
      if (!e.target.closest('.popup-close')) return;
      console.log(1);
      e.preventDefault();
      /**
       *
       * @type {HTMLElement}
       */
      const target = e.target;

      this.close(target.closest('.popup'));
    });

    /*Close in outside container*/

    document.addEventListener('click', (e) => {
      if (!e.target.matches('.popup')) return;
      e.preventDefault();
      /**
       *
       * @type {HTMLElement}
       */
      const target = e.target;

      this.close(target);
    });
  }

  /**
   *
   * @param popup {HTMLElement}
   */
  open(popup) {
    this.body.classList.add('overflow-hidden');
    popup.classList.add('popup-show');
  }

  /**
   *
   * @param popup {HTMLElement}
   */
  close(popup) {
    this.body.classList.remove('overflow-hidden');
    popup.classList.add('popup-hide');

    setTimeout(() => {
      popup.classList.remove('popup-show');
      popup.classList.remove('popup-hide');
    }, this.animationDuration);
  }
}
